/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          jQuery(document).ready(function() {
              jQuery('.popup').click(function(event) {
                  var width  = 575,
                      height = 400,
                      left   = ($(window).width()  - width)  / 2,
                      top    = ($(window).height() - height) / 2,
                      url    = this.href,
                      opts   = 'status=1' +
                          ',width='  + width  +
                          ',height=' + height +
                          ',top='    + top    +
                          ',left='   + left;

                  window.open(url, '', opts);

                  return false;
              });
          });

          jQuery.cookieBar({
              bottom: true,
              message: 'Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass wir Cookies verwenden.', //Message displayed on bar
              acceptText: 'Verstehe', //Text on accept/enable button
              expireDays: 365, //Number of days for cookieBar cookie to be stored for
              domain: String(window.location.hostname), //Location of privacy policy
              referrer: String(document.referrer) //Where visitor has come from
          });

        //var a = new Share('.share-button');
        //console.log('hello');

        var gform = jQuery(document).find('.gform_wrapper').attr('class');
        if (typeof gform !== 'undefined' && gform !== 'false') {
            jQuery(document).on('gform_post_render', function () {
                var form = jQuery('.gform_wrapper');
                var required = jQuery('.gfield_contains_required');
                var controlGroup = jQuery('.gfield');
                required.each(function () {
                    jQuery(this).find('input, textarea, select').not('input[type="checkbox"], input[type="radio"]').attr('required', 'true');
                });
                jQuery('.gform_fields').each(function () {
                    jQuery(this).addClass('row');
                });
                controlGroup.each(function () {
                    jQuery(this).addClass('form-group').find('input, textarea, select').not('input[type="checkbox"], input[type="radio"]').after('<span class="help-block"></span>').addClass('form-control');
                });
                form.find("input[type='submit'], input[type='button']").addClass('btn btn-primary').end().find('.gfield_error').removeClass('gfield_error').addClass('has-error');
                jQuery('.gfield_checkbox, .gfield_radio').find('input[type="checkbox"], input[type="radio"]').each(function () {
                    var sib = jQuery(this).siblings('label');
                    jQuery(this).prependTo(sib);
                }).end().each(function () {
                    jQuery(this).after('<span class="help-block"></span>');
                    if (jQuery(this).is('.gfield_checkbox')) {
                        jQuery(this).addClass('checkbox');
                    } else {
                        jQuery(this).addClass('radio');
                    }
                });
                jQuery('.validation_message').each(function () {
                    var sib = jQuery(this).prev().find('.help-block');
                    jQuery(this).appendTo(sib);
                });
                jQuery('.validation_error').addClass('alert alert-danger');
                jQuery('.gf_progressbar').addClass('progress progress-striped active').children('.gf_progressbar_percentage').addClass('progress-bar progress-bar-success');
            });
            //console.log('form: y');
        } else {
            //console.log('form: n');
            //return false;
        }
        jQuery(document).ready(function(){
            jQuery(document).bind('gform_confirmation_loaded', function(){
                jQuery('#gewinnspiel, .mobile_image').css({"background-image": "none"});
                jQuery('.confirmImg').css({"display": "block"});
            });
        });
        //$('#collapseExample').on('hidden.bs.collapse', function () {
        //    $('.response-hint > span img').css({"display": "block"});
        //});
        //$('#collapseExample').on('shown.bs.collapse', function () {
        //    $('.response-hint > span img').css({"display": "none"});
        //});
          $('[data-toggle="tooltip"]').tooltip();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
